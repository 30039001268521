import 'regenerator-runtime/runtime';

const axios = require('axios');

const URL = 'https://ftn-scripts-staging.herokuapp.com/api/news';

let TOTAL_ELEMENTS = 0;

let page = 1;

let LIMIT = 3;

const news = [];

const fetchNews = async () => {
  const response = await axios.default.get(`${URL}`, {
    params: {
      limit: LIMIT,
      page,
      domain: 'codeference.org',
      sort: '-createdAt',
    },
  });

  news.push(...(response.data.data ?? []));

  TOTAL_ELEMENTS = +response.headers['total-elements'];

  document.querySelector('#spinner-news').innerHTML = '';

  document.querySelector('#news-list').className = '';
  document.querySelector('#news-list').innerHTML = formNews(news);

  if (news.length === TOTAL_ELEMENTS)
    document.querySelector('#load-more-btn').className += ' news-hidden';
  else
    document.querySelector('#load-more-btn').className += document
      .querySelector('#load-more-btn')
      .className.replace('news-hidden', '');
};

const formRow = (row = []) => {
  let rowHtml = '<div class ="row">';

  const newsHtml = row
    .map((news) => {
      const [image] = news?.images ?? [];

      return `
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="blog-post-thumb">
            <div class="img">
              <img src=${image?.url ?? ''} width="200" height="200" alt="">
            </div>
            <div class="blog-content">
              <h3>
                  <a href="assets/pages/news/novosti.html?id=${news?._id}">${
        news?.title
      }</a>
              </h3>
              <div class="text">
                ${news?.content}
              </div>
              <a href="assets/pages/news/novosti.html?id=${
                news?._id
              }" class="main-button">Pročitaj više</a>
            </div>
          </div>
        </div>
    `;
    })
    .join(' ');

  rowHtml += newsHtml;

  rowHtml += '</div>';
  return rowHtml;
};

const formNews = (allNews = []) => {
  const BUFFER_SIZE = 3;
  let html = ``;

  const rowBuffers = [];

  let buffer = [];
  let counter = 0;

  allNews.forEach((singleNews, index) => {
    if (counter < BUFFER_SIZE) {
      buffer.push(singleNews);
      counter++;
    } else {
      rowBuffers.push(buffer);
      buffer = [];
      buffer.push(singleNews);
      counter = 1;
    }

    if (index === allNews.length - 1 && counter) rowBuffers.push(buffer);
  });

  rowBuffers.forEach((row) => (html += formRow(row)));

  return html;
};

document.getElementById('load-more-btn').onclick = async () => {
  page++;
  await fetchNews().catch(console.error);
};

(async () => await fetchNews().catch(console.error))();
